.category-filter {
  width: var(--main-width);
  max-width: var(--main-max-width);
  margin: 0 auto;

  position: relative;

  overflow-y: hidden;
  scroll-behavior: smooth;
}

.category-filter__item {
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5rem 0.8rem;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}

.category-filter__item--active {
  border-bottom: 0.3rem solid black;
}

.category-filter__list {
  /* white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; */

  position: relative;

  display: flex;
  flex-wrap: nowrap;

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.category-filter__list::-webkit-scrollbar {
  display: none;
}

.category-filter::after {
  display: block;
  content: "";
  width: 0.8rem;
  height: calc(100% - 0.3rem);
  /* background-color: aqua; */
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));

  right: 0;
  top: 0;
  position: absolute;
}
