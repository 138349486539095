.marc {
  width: var(--main-width);
  max-width: var(--main-max-width);
  margin: 0 auto;
  padding-top: 1.5rem;
  display: flex;
  align-items: baseline;
}

.marc__info {
  margin-left: 2rem;
}
