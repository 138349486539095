.image-list {
  width: var(--main-width);
  max-width: var(--main-max-width);
  margin: 0 auto;
}

.image-list__count {
  margin-top: 0;
  margin-bottom: 0.85em;
}

.image-list__list {
  display: grid;
  grid-template-columns: repeat(var(--img-cols), 1fr);
  grid-column-gap: var(--main-gutter);
  grid-row-gap: var(--main-gutter);
}

.image-list__item {
  display: block;
}

.image-list__year {
  font-weight: normal;
  font-size: 0.6em;
}

.image-list__title {
  margin-top: 0.6em;
  margin-bottom: 0.8em;
}

.image-list__comment {
  font-size: 0.75em;
  font-weight: normal;
}
