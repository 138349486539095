/* .info__content a {
  color: var(--color-primary);
} */
.info__content a:hover {
  color: var(--color-primary);
}

.info__content a {
  text-decoration: underline;
  text-decoration-color: black;
  text-underline-offset: 0.4em;
  text-decoration-line: underline;
  text-decoration-thickness: 0.15em;
}

.info__lang {
  margin-top: 4rem;
}

@media only screen and (min-width: 900px) {
  .info__content {
    display: grid;
    grid-column-gap: calc(var(--main-gutter) * 2);
    grid-template-columns: repeat(6, 1fr);
    margin-top: var(--main-gutter);
  }
  .info__lang {
    margin-top: 0;
  }
}

.info__en {
  grid-column: span 3;
}

.info__de {
  grid-column: span 3;
}

@media only screen and (min-width: 1200px) {
  .info__en {
    grid-column: 2 / 4;
  }

  .info__de {
    grid-column: span 2;
  }
}

.info__year {
  display: inline-block;
  width: 6.2rem;
  margin-left: -6.2rem;
}
.info__year-paragraph {
  /* text-indent: -6.2rem; */
  margin: 0 0 0 6.2rem;
}

.info__content ul {
  padding-left: 1em;
  margin: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}
.info__content ul > li > p {
  display: inline;
}

.info__content ul > li::before {
  content: "–\00A0";
  color: var(--blue-color);
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
