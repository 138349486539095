:root {
  /* variables */
  --color-primary: #8d1515;
  --color-deactivated: #f3f3f3;
  --color-active: #8d1515;
  --main-gutter: 1.2rem;
  --main-max-width: 98rem;
  --main-width: calc(100% - 2 * var(--main-gutter));
  font-size: 13px;
  --img-cols: 2;
}

@media (min-width: 900px) {
  :root {
    font-size: 16px;
    --img-cols: 4;
  }
}

/* ::selection {
  background: var(--color-active);
  text-shadow: none;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.65;
}

input {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: 1px solid black;
  display: block;
  width: 100%;
  max-width: 20rem;
  padding: 0.5rem;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin-top: 0;
  margin-bottom: 0.4em;
}

h1,
h2,
h3 {
  margin-top: 0;
  font-weight: 700;
  margin-bottom: 0.3em;
  line-height: 1.3;
}

h4 {
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.1em;
  font-size: 0.8rem;
  margin-top: 0;
  margin-bottom: 0.9em;
}

h1 {
  font-size: 1.4rem;
}

h2 {
  font-size: 1.2rem;
  margin-top: 1.5em;
}

@media only screen and (min-width: 600px) {
  h2 {
    margin-top: -0.15em;
  }
}

h3 {
  font-size: 1rem;
  margin-top: 1.5em;
  margin-bottom: 0.1em;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 0.4em;
}

hr {
  border: none;
  height: 0;
  background-color: #222;

  margin-top: 1.8rem;
  margin-bottom: 2rem;
}
