.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: black;
  z-index: 300;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal__header {
  position: sticky;
  top: 0;
  z-index: 350;
  background-color: #fff;
  padding-top: calc(var(--main-gutter) / 2);
  height: 2.5rem;
  padding-bottom: calc(var(--main-gutter) / 2);
  margin-left: var(--main-gutter);
  margin-right: var(--main-gutter);
}

.modal__close {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiBmaWxsPSJub25lIj48cGF0aCBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMjkiIGQ9Im01MiA1MiA0MDguNSA0MDguNU01MiA0NjAuNSA0NjAuNSA1MiIvPjwvc3ZnPg==);
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50% 50%;
  padding: 0;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 350;
}

.modal__content {
  background-color: white;
  position: relative;

  width: var(--main-width);
  max-width: var(--main-max-width);
  min-height: 100%;
}

.modal__inner {
  margin: 0 var(--main-gutter) calc(2 * var(--main-gutter));
  /* height: 100%; */
}
