@media only screen and (min-width: 600px) {
  .detailImage__content {
    display: grid;
    grid-column-gap: var(--main-gutter);
    grid-template-columns: repeat(10, 1fr);
    /* margin-top: var(--main-gutter); */
  }
}

.detailImage__content a {
  /* padding-bottom: 0.3rem;
  border-bottom: 0.15rem solid black; */

  text-decoration: underline;
  text-decoration-color: black;
  text-underline-offset: 0.4em;
  text-decoration-line: underline;
  text-decoration-thickness: 0.15em;
}

.detailImage__content a:hover {
  color: var(--color-primary);
}

.detailImage__image {
  grid-column: span 7;
}

.detailImage__text {
  grid-column: span 3;
}

@media only screen and (min-width: 1300px) {
  .detailImage__image {
    grid-column: span 8;
  }

  .detailImage__text {
    grid-column: span 2;
  }
}

.detailImage__content--portrait .detailImage__image {
  grid-column: span 5;
}

.detailImage__content--portrait .detailImage__text {
  grid-column: span 5;
}
