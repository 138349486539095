.spinner {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 4rem;
}

.spinner__inner {
  border: 0.5rem solid var(--color-deactivated);
  border-top: 0.5rem solid var(--color-active);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
