.home {
  /* other styles for .home container */
}

.home__header {
  position: sticky;
  top: 0;
  background-color: #fff; /* set the desired background color */
  /* other styles for .home__header */
  z-index: 100;
  margin-bottom: var(--main-gutter);
}
